import React from "react"
import { courses } from "../components/assets/data/dummydata"
import { FaBook } from "react-icons/fa"
import { AiFillStar } from "react-icons/ai"
import { NavLink } from "react-router-dom"
import { HiOutlineArrowNarrowRight } from "react-icons/hi"

export const CoursesHome = () => {
  return (
    <>
      <section className='courses bg-transparent py-16 'style={{ backgroundImage: 'linear-gradient(90deg, #F7F0FF 0%, #E9F7F8 100%)' }}>
        <div className='w-4/5 m-auto'>
          <div className='heading mb-16'>
            <h1 className='mt-5 text-4xl font-bold leading-tight text-gray-900 sm:leading-tight sm:text-5xl lg:text-6xl lg:leading-tight font-pj'>
            Our Top Online Courses <br />
            Learn. Grow. Achieve.           </h1>
            <p className=' text-lg text-gray-600 font-inter'>Explore our top-rated courses that are designed to help you master new skills, expand your knowledge, and advance in your career.</p>
          </div>
          <div className='grid grid-cols-3 gap-8 md:grid-cols-1'>
            {courses.map((item) => (
              <div className='box rounded-lg shadow-shadow1 bg-white'>
                <div className='images rounded-t-lg relative overflow-hidden h-40 w-ful'>
                  <img src={item.cover} alt='' className='rounded-t-lg object-cover w-full h-full transition ease-in-out delay-150 cursor-pointer hover:scale-125 duration-300' />
                  {/* <div className='categ flex gap-4 absolute top-0 m-3'>
                    <span className='text-[14px] bg-blue-700 p-1 px-3 text-white rounded-[5px] shadow-md'>Finance</span>
                    <span className='text-[14px] bg-pink-700 p-1 px-3 text-white rounded-[5px] shadow-md'>Lifestyle</span>
                  </div> */}
                </div>
                <div className='text p-3'>
                  <div className='flex justify-between items-center'>
                    <div className='flex items-center'>
                      <FaBook />
                      <span className='text-[14px] ml-2'> 10 lessons</span>
                    </div>
                    {/* <div className='flex items-center'>
                      <AiFillStar className='text-orange-500' />
                      <span className='text-[14px] ml-2'> 4.50(2)</span>
                    </div> */}
                  </div>

                  <h3 className='text-black my-4 font-medium h-10'>{item.title}</h3>
                  {/* <div className='user flex items-center'>
                    <img className='rounded-full' src='https://secure.gravatar.com/avatar/75ec18a5bf959aab895830be3a78cb34?s=50&d=mm&r=g' alt='' />
                    <span className='text-[14px] ml-2'> Abhishek</span>
                  </div> */}
                </div>
                {/* <div to='/' className='flex items-center justify-between border-t border-gray-200 p-3'>
                  <span className='text-sm text-primary'>Free</span>
                  <NavLink className='text-[14px] ml-2 flex items-center'>
                    Know Details <HiOutlineArrowNarrowRight />
                  </NavLink>
                </div> */}
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}
