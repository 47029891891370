// import React from "react";
// // import { BackgroundLines } from "./background-lines";
// import { BackgroundLines } from "./background-lines"; 


// export function BackgroundLinesDemo() {
//   return (
//     <section className='about relative py-16'>
//         <div className='container relative z-10'>
//         <BackgroundLines className="absolute inset-0 z-0" /> 

//         <div className="flex items-center justify-center w-full flex-col px-4 relative z-20">
//           <h2
//             className="bg-clip-text text-transparent text-center bg-gradient-to-b from-neutral-900 to-neutral-700 dark:from-neutral-600 dark:to-white text-2xl md:text-4xl lg:text-7xl font-sans py-2 md:py-10 relative z-20 font-bold tracking-tight">
//             Sanjana Airlines, <br /> Sajana Textiles.
//           </h2>
//           <p className="max-w-xl mx-auto text-sm md:text-lg text-neutral-700 dark:text-neutral-400 text-center">
//             Get the best advices from our experts, including expert artists,
//             painters, marathon enthusiasts, and RDX, totally free.
//           </p>
//         </div>
//       </div>
//     </section>
//   );
// }



import React from "react";
import { BackgroundLines } from "./background-lines"; 

export function BackgroundLinesDemo() {
  return (
    <section className="about relative py-16">
      <div className="container relative ">
        {/* BackgroundLines positioned behind content */}
        <BackgroundLines className="absolute " /> 

        <div className="flex items-center justify-center w-full flex-col px-4 relative z-20">
          <h2
            className="bg-clip-text text-transparent text-center bg-gradient-to-b from-neutral-900 to-neutral-700 dark:from-neutral-600 dark:to-white text-2xl md:text-4xl lg:text-7xl font-sans py-2 md:py-10 relative z-20 font-bold tracking-tight">
             Empowering Futures, <br /> Where Education Meets Innovation.
          </h2>
          <p className="max-w-xl mx-auto text-sm md:text-lg text-neutral-700 dark:text-neutral-400 text-center">
          Join the revolution of learning, and navigate your path to success with Internscockpit.
          </p>
        </div>
      </div>
    </section>
  );
}