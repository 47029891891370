import React from "react"
import { FaGraduationCap, FaUsers } from "react-icons/fa"
import { GiEvilBook, GiWorld } from "react-icons/gi"
import teacherImg from "../components/assets/images/teacher.jpg"


export const InstructorHome = () => {
  return (
    <>
      <section className='instructor mb-16'>
      <section class="py-10 bg-gray-50 dark:bg-slate-800 sm:py-16 lg:py-24">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="max-w-xl mx-auto text-center">
            

        <div className='heading text-center py-12'>
      <h1 class="mb-4 text-4xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-4xl"><span class="relative inline-flex sm:inline">
                        <span class="bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] blur-lg filter opacity-30 w-full h-full absolute inset-0"></span>
                        <span class="relative">Our Instructor</span>
                    </span></h1>
                    <h1 class="px-6 text-lg text-gray-600 font-inter">Our expert instructors are dedicated to guiding you, ensuring a transformative learning experience.</h1>  
            
          </div>
        </div>

        <div class="grid items-center grid-cols-1 mt-12 gap-y-10 lg:grid-cols-5 sm:mt-20 gap-x-4">
            <div class="space-y-8 lg:pr-16 xl:pr-24 lg:col-span-2 lg:space-y-12">
                <div class="flex items-start">
                    <svg class="flex-shrink-0 text-green-500 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
                    </svg>
                    <div class="ml-5">
                        <h3 class="text-xl font-semibold text-black dark:text-slate-50">Experienced Industry Leaders</h3>
                        <p class="mt-3 text-base text-gray-600 dark:text-slate-300">Our teachers are not just educators—they are professionals with years of experience in their respective fields. </p>
                    </div>
                </div>

                <div class="flex items-start">
                    <svg class="flex-shrink-0 text-blue-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    <div class="ml-5">
                        <h3 class="text-xl font-semibold text-black dark:text-slate-50">Personalized Learning Support</h3>
                        <p class="mt-3 text-base text-gray-600 dark:text-slate-300">Every learner is unique, and our teachers are committed to tailoring their teaching style to fit your needs.</p>
                    </div>
                </div>

                <div class="flex items-start">
                    <svg class="flex-shrink-0 text-red-500 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                    </svg>
                    <div class="ml-5">
                        <h3 class="text-xl font-semibold text-black dark:text-slate-50">Lifelong Mentors</h3>
                        <p class="mt-3 text-base text-gray-600 dark:text-slate-300">Our educators go beyond the classroom, offering continued mentorship to support your career and personal development.</p>
                    </div>
                </div>
            </div>

            <div class="lg:col-span-3">
                <img class="w-full rounded-lg shadow-xl" src={teacherImg} alt="Dashboard screenshot" />
            </div>
        </div>
    </div>
</section>
 
      </section>
    </>
  )
}

