import React, { useState } from 'react';
import vr from "../components/assets/images/Virtual-Reality-Headset.png"

import heroImg from "../components/assets/images/young-man-woman-protective-glasses-doing-experiments-robotics-laboratory-robot_11zon-scaled.jpg"

import heroImgback from "../components/assets/images/hero-shape-purple.png"
import { FiSearch } from "react-icons/fi"
import { BsFillLightningChargeFill } from "react-icons/bs"
import { FaBookReader, FaGraduationCap, FaUsers } from "react-icons/fa"
import { About } from "./About"
//import { Abouthome } from "./Abouthome"
import { Abouthome } from "./About-home"
import { Courses } from "./Courses"
import { InstructorHome } from "./InstructorHome"
//import { Blog } from "./Blog"
import { CoursesHome } from "./Courses-home"
import { Blog } from './BlogHome';


export const Home = () => {
  return (
    <>
      <HomeContent />
      <Abouthome/>
      <br />
      <br />
      <br />
      <CoursesHome />
      <InstructorHome />
      
      <Blog />
    </>
  )
}


export const HomeContent = () => {
  return (
    
<div class="overflow-x-hidden bg-gray-50">
    
    <section class="pt-12 bg-gray-50 sm:pt-16">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="max-w-2xl mx-auto text-center">
                <h1 class="px-6 text-lg text-gray-600 font-inter">Learn, Explore, and Grow with Virtual Reality at Internscockpit</h1>
                <p class="mt-5 text-4xl font-bold leading-tight text-gray-900 sm:leading-tight sm:text-5xl lg:text-6xl lg:leading-tight font-pj">
                Internscockpit  Learning, consulting, 
                    <span class="relative inline-flex sm:inline">
                        <span class="bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] blur-lg filter opacity-30 w-full h-full absolute inset-0"></span>
                        <span class="relative"> internships. </span>
                    </span>
                </p>

                <div class="px-8 sm:items-center sm:justify-center sm:px-0 sm:space-x-5 sm:flex mt-9">
                    <a
                        href="#"
                        title=""
                        class="inline-flex items-center justify-center w-full px-8 py-3 text-lg font-bold text-white transition-all duration-200 bg-gray-900 border-2 border-transparent sm:w-auto rounded-xl font-pj hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                        role="button"
                    >
                        Get Started
                    </a>

                    <a
                        href="#"
                        title=""
                        class="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-lg font-bold text-gray-900 transition-all duration-200 border-2 border-gray-400 sm:w-auto sm:mt-0 rounded-xl font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-gray-900 focus:bg-gray-900 hover:text-white focus:text-white hover:border-gray-900 focus:border-gray-900"
                        role="button"
                    >
                        <svg class="w-5 h-5 mr-2" viewBox="0 0 18 18" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.18003 13.4261C6.8586 14.3918 5 13.448 5 11.8113V5.43865C5 3.80198 6.8586 2.85821 8.18003 3.82387L12.5403 7.01022C13.6336 7.80916 13.6336 9.44084 12.5403 10.2398L8.18003 13.4261Z"
                                stroke-width="2"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        Watch free demo
                    </a>
                </div>

                <p class="mt-8 text-base text-gray-500 font-inter">Internscockpit empowers your career journey through innovative E-Learning, free consultancy, and valuable internship opportunities, helping you gain essential skills and real-world experience for a successful future.</p>
            </div>
        </div>

        <div class="pb-12 bg-white">
            <div class="relative">
                <div class="absolute inset-0 h-2/3 bg-gray-50"></div>
                <div class="relative mx-auto">
                    <div class="lg:max-w-6xl lg:mx-auto">
                        <img class="transform scale-110" src="https://cdn.rareblocks.xyz/collection/clarity/images/hero/2/illustration.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
    
  )
}






// export const HomeContent = () => {
//   return (
//     <>
//       <section className='bg-cover bg-no-repeat bg-center py-10 h-[92vh] md:h-full'  style={{ backgroundImage: `url(${vr})` }}>
//         <div className='container'>
//           <div className='flex items-center justify-center md:flex-col'>
//             <div className='left w-1/2 text-white md:w-full'>
//               <h1 className='text-3xl  leading-tight text-white font-extrabold text-900'>
//               Learn, Explore, and Grow<br /> with Virtual Reality at <br /><span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">Internscockpit</span>
//               </h1>
//               <p class="text-sm mt-5  text-white-500 lg:text-xl font-semibold dark:text-gray-400">Internscockpit empowers your career journey through innovative E-Learning, free consultancy, and valuable internship opportunities, helping you gain essential skills and real-world experience for a successful future.</p>
              

              
//               <button type="button " class="text-white mt-5 bg-[#FF9119] hover:bg-[#FF9119]/80 focus:ring-4 focus:outline-none focus:ring-[#FF9119]/50 font-medium rounded-lg text-lg px-8 py-4 text-center inline-flex items-center dark:hover:bg-[#FF9119]/80 dark:focus:ring-[#FF9119]/40 me-2 mb-2">
              
//               Get Started
//               </button>  
//             </div>
//             <div className='right w-1/2 md:w-full relative'>
//               <div className='images relative'>
//                 {/* <img src={heroImgback} alt='' className=' absolute top-32 left-10 w-100 md:left-10' /> */}
//                 <div className='img h-[85vh] w-full'>
//                   {/* <img src={heroImg} alt='' className='h-full w-full object-contain z-20 relative' /> */}
//                 </div>
//               </div>
//               <div className='content'>
//                 {/* <button className='bg-white shadow-md absolute top-56 left-0 z-30 p-2 flex items-center rounded-md'>
//                   <div className='icon w-10 h-10 text-white rounded-full flex items-center justify-center bg-orange-400'>
//                     <BsFillLightningChargeFill size={25} />
//                   </div>
//                   <div className='text flex flex-col items-start px-4'>
//                     <span className='text-sm text-black'>Congrstulations</span>
//                     <span className='text-[12px]'>Your admission completed</span>
//                   </div>
//                 </button>
//                 <button className='bg-white shadow-md absolute bottom-32 left-48 z-30 p-2 flex items-center rounded-md pr-8'>
//                   <div className='icon w-10 h-10 text-white rounded-full flex items-center justify-center bg-blue-400'>
//                     <FaGraduationCap size={25} />
//                   </div>
//                   <div className='text flex flex-col items-start px-4'>
//                     <span className='text-sm text-black'>450K</span>
//                     <span className='text-[12px]'>Assisted Student</span>
//                   </div>
//                 </button>
//                 <button className='bg-white shadow-md absolute top-56 -right-32 z-30 p-2  md:top-96 md:-right-5 flex items-center rounded-md'>
//                   <div className='icon w-10 h-10 text-white rounded-full flex items-center justify-center bg-orange-400'>
//                     <FaUsers size={25} />
//                   </div>
//                   <div className='text flex flex-col items-start px-4'>
//                     <span className='text-sm text-black'>User Experience Class</span>
//                     <span className='text-[12px]'>Tomorrow is our</span>
//                   </div>
//                 </button> */}
//                 {/* <button className='bg-white shadow-md absolute top-32 right-32 z-30 p-2 flex items-center rounded-md'>
//                   <div className='icon w-10 h-10 text-white rounded-full flex items-center justify-center bg-indigo-400'>
//                     <FaBookReader size={25} />
//                   </div>
//                 </button> */}
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   )
// }
