// import React, { useState } from "react"
//import LogoImg from "../assets/images/logo-black.png"
import { LinkData } from "../assets/data/dummydata"
import { NavLink } from "react-router-dom"
// import { BiShoppingBag } from "react-icons/bi"

// import { HiOutlineMenuAlt1, HiViewGrid } from "react-icons/hi"

import { useState } from 'react';

export const Header = () => {
  const [expanded, setExpanded] = useState(false); // State for toggling the mobile menu

  return (
    <div className="overflow-x-hidden bg-gray-50">
      <header className="py-4 md:py-6">
        <div className="container px-4 mx-auto sm:px-6 lg:px-8">
          <div className="flex items-center justify-between">
            <div className="flex-shrink-0">
              <a
                href="#"
                title=""
                className="flex rounded outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
              >
                <img
                  className="w-auto h-8"
                  src="https://cdn.rareblocks.xyz/collection/clarity/images/logo.svg"
                  alt="Logo"
                />
              </a>
            </div>

            {/* Mobile menu button */}
            <div className="flex lg:hidden">
              <button
                type="button"
                className="text-gray-900"
                onClick={() => setExpanded(!expanded)} // Toggle menu on click
                aria-expanded={expanded}
              >
                {expanded ? (
                  <svg
                    className="w-7 h-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-7 h-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>

            {/* Desktop links */}
            <div className="hidden lg:flex lg:ml-16 lg:items-center lg:justify-center lg:space-x-10 xl:space-x-16">
                {LinkData.map((link) => (
                  <a
                    href={link.url}
                    key={link.id}
                    title={link.title}
                    className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                  >
                    {link.title}
                  </a>
                ))}
              </div>
              
            {/* Desktop login and signup */}
            <div className="hidden lg:ml-auto lg:flex lg:items-center lg:space-x-10">
              <a
                href="/LoginPage"
                title=""
                className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
              >
                Login
              </a>
              <a
                href="/SignUpPage"
                title=""
                className="inline-flex items-center justify-center px-6 py-3 text-base font-bold leading-7 text-white transition-all duration-200 bg-gray-900 border border-transparent rounded-xl hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                role="button"
              >
                Sign up
              </a>
            </div>
          </div>

          {/* Mobile navigation */}
          {expanded && (
            <nav>
              <div className="px-1 py-8">
                <div className="grid gap-y-7">
                  <a
                    href="#"
                    title=""
                    className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 transition-all duration-200 rounded-xl hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                  >
                    Features
                  </a>
                  <a
                    href="#"
                    title=""
                    className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 transition-all duration-200 rounded-xl hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                  >
                    Pricing
                  </a>
                  <a
                    href="#"
                    title=""
                    className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 transition-all duration-200 rounded-xl hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                  >
                    Automation
                  </a>
                  <a
                    href="#"
                    title=""
                    className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 transition-all duration-200 rounded-xl hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                  >
                     Login
                  </a>
                  <a
                    href="#"
                    title=""
                    className="inline-flex items-center justify-center px-6 py-3 text-base font-bold leading-7 text-white transition-all duration-200 bg-gray-900 border border-transparent rounded-xl hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    role="button"
                  >
                    Sign up
                  </a>
                </div>
              </div>
            </nav>
          )}
        </div>
      </header>
    </div>
  );
};


// export const Header = () => {
//   const [open, setOpen] = useState(false)
//   return (
//     <>

//       <header className='bg-[rgb(255,145,25)] py-4 text-white text-[20px] text-lg font-bold sticky z-50  top-0 left-0 w-full'>
//         <div className='container flex justify-between items-center'>
//           <div className='logo flex items-center gap-6'>
//             <img src={LogoImg} alt='logo' className='h-10' />
            
//           </div>
//           <nav className={open ? "mobile-view" : "desktop-view"}>
//             <ul className='flex items-center gap-6'>
//               {LinkData.map((link) => (
//                 <li key={link.id} onClick={() => setOpen(null)}>
//                   <NavLink className={({ isActive }) => (isActive ? "text-[rgb(255,145,25)] text-lg text-[20px]" : "text-[20px]")} to={link.url}>
//                     {link.title}
//                   </NavLink>
//                 </li>
//               ))}
//             </ul>
//           </nav>
//           <div className='account flex items-center gap-5'>
//             <button>
//               <BiShoppingBag size={25} />
//             </button>
//             <button>Login</button>{" "}
//             <button className='open-menu' onClick={() => setOpen(!open)}>
//               <HiOutlineMenuAlt1 size={25} />
//             </button>
//           </div>
//         </div>
//       </header>
//     </>
//   )
// }
